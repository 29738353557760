export default {
    install: function(Vue) {
        Vue.prototype.$permission = {
            permissionList: [],
            //判断当前操作是否在权限内
            is: function(api) {
                let apiurl = api.toLowerCase(); //转小写
                if(api==''){
                    return true;
                }else if(window.app.userInfo.permissions){
                    this.permissionList = window.app.userInfo.permissions;
                    if (this.permissionList.indexOf(apiurl)>-1) {
                        return true;
                    }
                    return false;
                }
            }
        };
    }
};
