import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import './element-variables.scss'
Vue.use(ElementUI);
import './base.css'
import './main.less'
import md5 from 'js-md5';
// import axios from 'axios';
import router from './router'

import http from '@/plugins/http.js' //axios实例化后引入取名http
Vue.prototype.http = http //放入全局
Vue.prototype.domain = http.domain; //放入全局
window._token= ''; //放入全局
window.domain = http.domain;
Vue.prototype.$md5 = md5
import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)
Vue.prototype.bus = new Vue();
Vue.config.productionTip = false;

import permission from './permission';
Vue.use(permission);

import common from '@/plugins/common';
Vue.prototype.$common = common

window.app = new Vue({
    router,
    render: h => h(App),
    data: function() {
        return {
            userInfo: '',
            selectData:[],
            count:{},
        };
    }
}).$mount('#app');
export default window.app
