import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    routes: [{
        path: '/',
        name: '登录',
        component: () => import('./views/login.vue')
    }, {
        path: '/index',
        name: '首页',
        component: () => import('./views/index.vue'),
        children: [{
            path: '/welcome',
            name: '欢迎',
            component: () => import('./views/welcome.vue'),
        }, {
            path: '/overview',
            name: '统计概况',
            component: () => import('./views/overview.vue'),
        }, {
            path: '/overviewCus',
            name: '统计概况客户',
            component: () => import('./views/overview/customer.vue'),
        }, {
            path: '/overviewOrder',
            name: '统计概况订单',
            component: () => import('./views/overview/order.vue'),
        }, {
            path: '/goodsList',
            name: '商品列表',
            component: () => import('./views/goods/list.vue'),
        }, {
            path: '/goodsDet',
            name: '商品列表详情',
            component: () => import('./views/goods/det.vue'),
        }, {
            path: '/goodsType',
            name: '商品分类',
            component: () => import('./views/goods/type.vue'),
        }, {
            path: '/goodsBrand',
            name: '商品品牌',
            component: () => import('./views/goods/brand.vue'),
        }, {
            path: '/goodsDetail',
            name: '商品统计',
            component: () => import('./views/goods/detail.vue'),
        }, {
            path: '/bureau',
            name: '站点管理',
            component: () => import('./views/bureau.vue'),
        }, {
            path: '/bureauAdd',
            name: '站点管理新增',
            component: () => import('./views/bureauAdd.vue'),
        },
        {
            path: '/admin',
            name: '销售业务员账号',
            component: () => import('./views/account/admin.vue')
        }, {
            path: '/orderIndex',
            name: '订单列表',
            component: () => import('./views/order/index.vue')
        }, {
            path: '/chargeback',
            name: '退单列表',
            component: () => import('./views/order/chargeback.vue')
        }, {
            path: '/customer',
            name: '客户管理',
            component: () => import('./views/customer.vue')
        }, {
            path: '/customerAdd',
            name: '客户管理新增',
            component: () => import('./views/customerAdd.vue')
        }, {
            path: '/banner',
            name: 'banner管理',
            component: () => import('./views/banner.vue')
        }, {
            path: '/notice',
            name: '公告管理',
            component: () => import('./views/notice.vue')
        }, {
            path: '/maintain',
            name: '保养单',
            component: () => import('./views/maintain/maintain.vue')
        }, {
            path: '/car',
            name: '车辆信息',
            component: () => import('./views/maintain/car.vue')
        }, {
            path: '/remind',
            name: '待提醒',
            component: () => import('./views/maintain/remind.vue')
        }, {
            path: '/ismaintain',
            name: '保养模块显示',
            component: () => import('./views/audit/ismaintain.vue')
        }, {
            path: '/auditChargeback',
            name: '退订订单',
            component: () => import('./views/audit/chargeback.vue')
        }, {
            path: '/customerDetail',
            name: '客户统计',
            component: () => import('./views/customerDetail.vue')
        }, {
            path: '/VisitRecordList',
            name: '拜访记录',
            component: () => import('./views/CustomerService/VisitRecord/VisitRecordList.vue')
        }, {
            path: '/VisitRecordDetail',
            name: '拜访详情',
            component: () => import('./views/CustomerService/VisitRecord/VisitRecordDetail.vue')
        }, {
            path: '/ClockinRecordsList',
            name: '打卡记录',
            component: () => import('./views/CustomerService/ClockinRecords/ClockinRecordsList.vue')
        }, {
            path: '/ClockinRecordsDetail',
            name: '打卡详情',
            component: () => import('./views/CustomerService/ClockinRecords/ClockinRecordsDetail.vue')
        }, {
            path: '/VisitStatistics',
            name: '拜访统计',
            component: () => import('./views/CustomerService/VisitStatistics.vue')
        }, {
            path: '/CustomerTypeList',
            name: '客户分类',
            component: () => import('./views/CustomerService/CustomerTypeList.vue')
        }, {
            path: '/dailyRecord',
            name: '日志管理',
            component: () => import('@/views/daily/dailyRecord.vue')
        }
        ]
    }, {
        path: '/subBureauIndex',
        name: '站点首页',
        component: () => import('./views/bureau/subIndex.vue'),
        children: [{
            path: '/subOverview',
            name: '站点统计概况',
            component: () => import('./views/bureau/overview.vue'),
        }, {
            path: '/subOverviewCus',
            name: '站点统计概况客户',
            component: () => import('./views/overview/customer.vue'),
        }, {
            path: '/subOverviewOrder',
            name: '站点统计概况订单',
            component: () => import('./views/overview/order.vue'),
        }, {
            path: '/subGoods',
            name: '商品列表',
            component: () => import('./views/goods/list.vue'),
        }, {
            path: '/subAdmin',
            name: '账号管理',
            component: () => import('./views/bureau/admin.vue'),
        }, {
            path: '/subOrder',
            name: '订单',
            component: () => import('./views/order/index.vue'),
        }, {
            path: '/subChargeback',
            name: '退单',
            component: () => import('./views/order/chargeback.vue'),
        }, {
            path: '/subCustomer',
            name: '退单',
            component: () => import('./views/customer.vue'),
        }, {
            path: '/subCustomerAdd',
            name: '客户管理新增',
            component: () => import('./views/customerAdd.vue')
        }, {
            path: '/subCustomerDet',
            name: '客户管理统计',
            component: () => import('./views/customerDetail.vue')
        }]
    }]
})