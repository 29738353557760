export default {
    // ---------------------------中国标准时间 转 年月日start----------------------------------------
    timeFormatter(time,type) {//type=year返回年 month返回月 不传返回天
        if (time) {
            const d = new Date(time);
            let timeString;
            if(type=='year'){
                timeString = `${this.timeFormat(d.getFullYear())}`;
            }else if(type=='month'){
                timeString = `${this.timeFormat(d.getFullYear())}-${this.timeFormat(d.getMonth() + 1)}`;
            }else {
                timeString = `${this.timeFormat(d.getFullYear())}-${this.timeFormat(d.getMonth() + 1)}-${this.timeFormat(d.getDate())}`;
            }
            return timeString
        } else {
            return ''
        }
    },
    timeFormat(val) {
        return val < 10 ? `0${val}` : val;
    },
    // ---------------------------中国标准时间 转 年月日end----------------------------------------
}
